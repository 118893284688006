import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';

const AddActionItem = () =>{
    const[id, setId] = useState("");
    const locale = "nl";
    const platform ="TWK";
    const [lotDisplayId, setLotDisplayId] = useState("");

    const query = gql`
    query LotBiddingData($lotDisplayId: String!, $locale: String!, $platform: Platform!) {
    lotDetails(displayId: $lotDisplayId, locale: $locale, platform: $platform){lot { followersCount } }}
    `;

    const {loading, error, data} = useQuery(query, {variables: {lotDisplayId, locale, platform}, refetchOnWindowFocus: false, enabled: false});


    const getFollowerCount = (event) =>
    {
        event.preventDefault();
        if(id !== ""){
            setLotDisplayId(id);
            setId("");
        }
    }

    //if (loading) return <p>Loading...</p>;
    //if (error) return <p>Error: {error.message}</p>;

    return(
        <div>
            <div className="FollowerCount">
                <form onSubmit={ getFollowerCount }>
                    <input type="text" value={id} onChange={(e) => setId(e.target.value)}/>
                    <input type="submit" value="Get"/>
                </form>
            </div>
            <div>
                    {
                            data?.lotDetails?.lot?.followersCount
                        }
                    
                
            </div>
            </div>
        )
}

export default AddActionItem;