import React, { Component, useState } from "react";
import TroostwijkDataService from "../services/troostwijk.service";
import { useLazyQuery, gql } from '@apollo/client';

const locale = "nl";
const platform ="TWK";

const getLotQuery = gql`
query LotBiddingData($lotDisplayId: String!, $locale: String!, $platform: Platform!) {
lotDetails(displayId: $lotDisplayId, locale: $locale, platform: $platform){lot { id displayId auctionId itemId condition currentBidAmount {
    cents currency} biddingStatus bidsCount highestBidder startDate endDate followersCount appearance attributes {name value} title description{ description additionalInformation } } }}
`;
 

const AddTroostwijkLot = (props) =>{
    const [displayId, setDisplayId] = useState("");
    
    const [getLot, {loading, error, data}] = useLazyQuery(getLotQuery, {variables: {displayId, locale, platform}, refetchOnWindowFocus: false, enabled: false, onCompleted: (data) => {
        saveLot(data);
      }});
   
    const addLot = (event) =>{
        event.preventDefault();
        if(displayId !== ""){
            let hasItem = false;
        
            //Check if already exists in db
            TroostwijkDataService.findByDisplayId(displayId)
              .then(response => {
                //this.setState({
                const troostwijkLots = response.data
                //});
                hasItem = troostwijkLots.length !== 0;
              })
              .catch(e => {
                console.log(e);
              });
        
              if(hasItem === false)
              {
                console.log(hasItem);
                getData(displayId);
                //this.saveTroostwijkLot();
              }
              else
              {
                
              }

            setDisplayId("");
        }
    }

    const saveLot = (res) =>{
        if(res.lotDetails.lot.displayId === displayId){
            setDisplayId("");
            const lot = res.lotDetails.lot;
            
        var data = {
            lotId: lot.id,
            displayId: lot.displayId,
            auctionId: lot.auctionId,
            itemId: lot.itemId,
            itemCondition: lot.condition,
            currentBidAmount: lot.currentBidAmount.cents,
            currentBidCurrency: lot.currentBidAmount.currency,
            biddingStatus: lot.biddingStatus,
            bidsCount: lot.bidsCount,
            highestBidder: lot.highestBidder,
            startDate: lot.startDate,
            endDate: lot.endDate,
            followersCount: lot.followersCount,
            appearance: lot.appearance,
            title: lot.title,
            attributes: "",
            description: lot.description?.description,
            additionalInformation: lot.description?.additionalInformation,
            notes: "",
            estimatedValue: 0,
        };
    
        TroostwijkDataService.create(data)
          .then(response => {
            /*
            this.setState({
                lotId: response.data.id,
                displayId: response.data.displayId,
                auctionId: response.data.auctionId,
                itemId: response.data.itemId,
                itemCondition: response.data.condition,
                currentBidAmount: response.data.currentBidAmount.cents,
                currentBidCurrency: response.data.currentBidCurrency,
                biddingStatus: response.data.biddingStatus,
                bidsCount: response.data.bidsCount,
                  highestBidder: response.data.highestBidder,
                  startDate: response.data.startDate,
                  endDate: response.data.endDate,
                  followersCount: response.data.followersCount,
                  appearance: response.data.appearance,
                  title: response.data.title,
                  attributes: response.data.attributes,
                  description: response.data.description,
                  additionalInformation: response.data.additionalInformation,
                  notes: response.data.notes,
                  estimatedValue: response.data.estimatedValue,
            });

            console.log(JSON.stringify(this.state));
            */
          })
          .catch(e => {
            console.log(e);
          });
        }
      }
    
    const getData = async (lotDisplayId) => {
        const res = await getLot({
            variables: {lotDisplayId, locale, platform},
            refetchOnWindowFocus: false, 
            enabled: false,
            updateQuery() {}, 
        });
    }
    
    return(
        <div className="AddItem">
            <form onSubmit={ addLot }>
                <input type="text" placeholder="A1-17696-409" value={displayId} onChange={(e) => setDisplayId(e.target.value) }/>
                <input type="submit" value="Toevoegen"/>
            </form>
        </div>
    )
}

export default AddTroostwijkLot;
/*
class AddTroostwijkLot extends Component {
  constructor(props) {
    super(props);
    this.onChangeDisplayId = this.onChangeDisplayId.bind(this);
    this.saveTroostwijkLot = this.saveTroostwijkLot.bind(this);
    this.addLot = this.addLot.bind(this);

    this.state = {
      id: null,
      locale: "nl",
      platform: "TWK",

      lotId: "",
      displayId: "",
      auctionId: "",
      itemId: "",
      itemCondition: "",
      currentBidAmount: 0,
      currentBidCurrency: "",
      biddingStatus: "",
      bidsCount: 0,
        highestBidder: "",
        startDate: 0,
        endDate: 0,
        followersCount: 0,
        appearance: "",
        title: "",
        attributes: "",
        description: "",
        additionalInformation: "",
        notes: "",
        estimatedValue: "",
    };
  }

  getData = async () => {
    const displayId = this.state.displayId;
    let res = await this.props.client.query({
      query: getLotQuery,
      variables: {displayId, locale, platform}
    })
    console.log(res)
    // Set the state to make changes in UI
  }

  onChangeDisplayId(e) {
    const displayId = e.target.value;

    this.setState({
      displayId: displayId
    });
  }

  saveTroostwijkLot() {
    var data = {
        lotId: this.state.lotId,
        displayId: this.state.displayId,
        auctionId: this.state.auctionId,
        itemId: this.state.itemId,
        itemCondition: this.state.itemCondition,
        currentBidAmount: this.state.currentBidAmount,
        currentBidCurrency: this.state.currentBidCurrency,
        biddingStatus: this.state.biddingStatus,
        bidsCount: this.state.bidsCount,
          highestBidder: this.state.highestBidder,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          followersCount: this.state.followersCount,
          appearance: this.state.appearance,
          title: this.state.title,
          attributes: this.state.attributes,
          description: this.state.description,
          additionalInformation: this.state.additionalInformation,
          notes: this.state.notes,
          estimatedValue: this.state.estimatedValue,
    };

    TroostwijkDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          lotId: this.state.lotId,
        displayId: this.state.displayId,
        auctionId: this.state.auctionId,
        itemId: this.state.itemId,
        itemCondition: this.state.itemCondition,
        currentBidAmount: this.state.currentBidAmount,
        currentBidCurrency: this.state.currentBidCurrency,
        biddingStatus: this.state.biddingStatus,
        bidsCount: this.state.bidsCount,
          highestBidder: this.state.highestBidder,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          followersCount: this.state.followersCount,
          appearance: this.state.appearance,
          title: this.state.title,
          attributes: this.state.attributes,
          description: this.state.description,
          additionalInformation: this.state.additionalInformation,
          notes: this.state.notes,
          estimatedValue: this.state.estimatedValue,
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  addLot(event)
  {
    event.preventDefault();
    let hasItem = false;

    //Check if already exists in db
    TroostwijkDataService.findByLotDisplayId(this.state.displayId)
      .then(response => {
        //this.setState({
        const troostwijkLots = response.data
        //});
        hasItem = troostwijkLots.length !== 0;
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

      if(hasItem === false)
      {
        this.getData();
        //this.saveTroostwijkLot();
      }
  }

  render() {
    const { displayId } = this.state;

    return(
        <div className="AddItem">
            <form onSubmit={ this.addLot }>
                <input type="text" placeholder="A1-17696-409" value={displayId} onChange={this.onChangeDisplayId }/>
                <input type="submit" value="Toevoegen"/>
            </form>
        </div>
    )
  }
}

export default AddTroostwijkLot;
*/