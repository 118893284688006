import logo from './logo.svg';
import './App.css';
import AddAuctionItem from './components/add-troostwijk.component';//'./components/AddAuctionItem';
import AuctionItems from './components/troostwijk-lots.component';//'./components/AuctionItems';
import GetFollowersCount from './components/GetFollowersCount';

function App(client) {
  return (
    <div className="App">
      <GetFollowersCount />
    </div>
  );
}

export default App;

/*
<AddAuctionItem client={client}/>
<AuctionItems/>
*/