import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

const client = new ApolloClient({
    uri: 'https://storefront.tbauctions.com/storefront/graphql',
    cache: new InMemoryCache()
  });

  
  /*client
  .query({
    query: gql`
    query LotBiddingData($lotDisplayId: String!, $locale: String!, $platform: Platform!) {
  lotDetails(displayId: $lotDisplayId, locale: $locale, platform: $platform){lot { id displayId auctionId currentBidAmount {
        cents currency} biddingStatus bidsCount startDate endDate  followersCount  appearance attributes {name value} title description{ additionalInformation } } }}
  `,

    variables: {"lotDisplayId":"A1-18019-844","locale":"nl","platform":"TWK"}
  })
  .then((result) => console.log(result));*/
export default client;