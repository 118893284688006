import http from "../http-common";

class TroostwijkDataService {
  getAll() {
    return http.get("/troostwijk");
  }

  get(id) {
    return http.get(`/troostwijk/${id}`);
  }

  create(data) {
    return http.post("/troostwijk", data);
  }

  update(id, data) {
    return http.put(`/troostwijk/${id}`, data);
  }

  delete(id) {
    return http.delete(`/troostwijk/${id}`);
  }

  deleteAll() {
    return http.delete(`/troostwijk`);
  }

  findByDisplayId(displayId) {
    return http.get(`/troostwijk/?displayId=${displayId}`);
  }
}

export default new TroostwijkDataService();